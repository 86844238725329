<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>榜单详情</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">榜单</a></li>
        <li>详情</li>
      </ol>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>登录详情</h3>-->
<!--      </div>-->
      <div class="box-body">
        <table class="view">
          <tr>
            <th>编号：</th>
            <td>{{item.id}}</td>
            <th>状态：</th>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
          <tr>
            <th>名称：</th>
            <td>{{item.name}}</td>
            <th>人数：</th>
            <td>{{item.number}}</td>
          </tr>
          <tr>
            <th>开始时间：</th>
            <td>{{item.begin.format('yyyy-MM-dd')}}</td>
            <th>结束时间：</th>
            <td>{{item.end.format('yyyy-MM-dd')}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box">
     <div class="box-head">
       <h3>名单列表</h3>
     </div>
      <div class="box-body">
          <a-empty v-if="lists.length == 0" />
          <table class="list" v-if="lists.length > 0">
          <tr>
            <!-- <th style="width: 120px;">#</th> -->
            <th style="width: 120px;">排名</th>
            <th style="width: 120px;">得分</th>
            <th>用户</th>
            <th>手机号</th>
            <th>角色</th>
            <th style="width: 120px;">奖金</th>
            <th style="width: 120px;">状态</th>

            <th>操作</th>
          </tr>
          <tr v-for="item in lists">
            <!-- <td>{{item.id}}</td> -->
            <td>
              <div>{{item.ranking}}</div>
            </td>
            <td>
              <div>{{item.score}}</div>
            </td>
            <td>
              <div>{{item.user.nickname}}</div>
            </td>
            <td>
              <div v-if="item.user.mobile">{{item.user.mobile}}</div>
              <div v-else>空</div>
            </td>
            <td>
              <div>{{item.role.name}}</div>
            </td>
            <td>
              <div>{{item.bonus}}</div>
            </td>
            <td style="width: 120px;">
              <label class="label" v-if="item.state" :class="[item.state.value]">{{item.state.label}}</label>
              <div><small>{{item.remark}}</small></div>
            </td>
            <td>
                <a-button-group size="small">
                  <a-tooltip placement="topRight" title="确认到账" v-if="item.state.value != STATE_FINISH">
                        <a-popconfirm placement="bottomLeft" title="确认该笔充值已到账吗？" @confirm="finish(item)">
                          <a-button style="color:green;"><a-icon type="check" />确认到账</a-button>
                        </a-popconfirm>
                      </a-tooltip>
                       <a-tooltip placement="topRight" title="查看错因" v-if="item.state.value == STATE_FAILIURE && item.response!=null">
                        <a-popconfirm placement="bottomRight" :title="item.response">
                          <a-button style="color:gray;"><a-icon type="search" />查看错因</a-button>
                        </a-popconfirm>
                      </a-tooltip>
                </a-button-group>


              </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="box">
      <div class="box-head">
        <h3>详细信息</h3>
      </div>
      <div class="box-body">
      <table class="view">
          <tr>
            <th>所属用户：</th>
            <td>{{item.user.nickname}}</td>
            <th>所属角色：</th>
            <td>{{item.role.name}}</td>
          </tr>
          <tr>
            <th>答题量：</th>
            <td>{{item.count.answers}}</td>
            <th>得分：</th>
            <td>{{item.score}}</td>
          </tr>
          <tr>
            <th>单题分值：</th>
            <td>{{item.count.answers}}</td>
            <th>完成时间：</th>
            <td>
              <span v-if="item.finished">{{item.finished.format('yyyy-MM-dd hh:mm:ss')}}</span>
            </td>
          </tr>
        <tr>
          <th>备注：</th>
          <td colspan="3">{{item.remark}}</td>
        </tr>
        <tr>
          <th>修改时间：</th>
          <td>{{item.changed.format('yyyy-MM-dd hh:mm:ss')}}</td>
          <th>操作时间：</th>
          <td>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</td>
        </tr>
      </table>
      </div>
    </div> -->

  </div>
</template>


<script>
export default {
  name: 'SysOplogDetail',
  data() {
    return {
      item: {
        staff: {}, records: [],
        changed: new Date(), created: new Date()
      },
      lists: []
    }
  },
  mounted() {
    this.request();
  },

  methods: {
    request() {
      let id = this.$route.params.id;
      this.$get('/test/rank/detail/' + id).then(res => {
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          this.$util.timeFields(ajax.data.item);
          this.item = ajax.data.item;
          this.lists = ajax.data.lists;
        }
      })
    },

    finish(item) {
            
            this.$post('/test/list/finish/' + item.id).then(res=>{
                let ajax = res.data;
                if (ajax.code == 0) {
                    setTimeout(()=>{
                        this.request();
                    }, 500);
                }
            });
            
        }
  }
}
</script>

<style scoped lang="less">
</style>